import React from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import Layout from "../../../../components/layout";
import OrderButton from "../../../../components/orderbutton";
import ProductTable from "../../../../components/productTable";
import SEO from "../../../../components/seo";
import { Col, Container, Row } from "react-bootstrap";
import Content from "../../../../content/produk/controller/multimedia/LISTEN Q3C/Product-Page.json";
import ProductImage from "../../../../images/product/controller/multimedia/LISTEN Q3C/Product-Summary-Listen-Q3C.jpg";
import Datasheet from "../../../../content/produk/controller/multimedia/LISTEN Q3C/datasheet-listen-ls-q3c.pdf";
const listenlsq3c = () => (
    <Layout>
        <SEO
            title={Content.seo.title}
            description={Content.seo.description}
            lang={Content.seo.lang}
        />
        <section id={"produk"} className={"section-bg"}>
            <Container>
                <div className={"section-title"}>
                    <h1>Listen LS-Q3C</h1>
                    <Row>
                        <Col>
                            <Image
                                src={ProductImage}
                                fluid
                                alt={"Gambar modul"}
                            />
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col>
                        <div className={"section-title"}>
                            <h2 className={"text-center"}>Product Summary</h2>
                        </div>
                        <p>
                            LS-Q3C adalah player multimedia penuh warna dari
                            listenvision yang ditenagai oleh ARM A8 1.2GHz dan
                            kemampuan remote management menjadikan LS-Q3S
                            pilihan yang tepat untuk layar LED anda
                        </p>
                        <p>
                            <OrderButton
                                messageText={Content.messageText}
                                datasheet={Datasheet}
                            />
                        </p>
                    </Col>
                    <Col>
                        <div className={"section-title"}>
                            <h2 className={"text-center"}>Specification</h2>
                        </div>
                        <ProductTable
                            tableName={"LED Unit Cabinet"}
                            tableData={Content.specification}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    </Layout>
);

listenlsq3c.propTypes = {
    siteTitle: PropTypes.string,
};

listenlsq3c.defaultProps = {
    siteTitle: ``,
};

export default listenlsq3c;
